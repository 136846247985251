<template>
  <el-carousel v-if="allBuildingData.value && allBuildingData.value.length > 1"
               style="height: 100%;"
               height="100%"
               arrow="always"
               :autoplay="false">
    <el-carousel-item v-for="(item, index) in allBuildingData.value" :key="index">
      <div class="building-list">
        <!-- class: warning-tips 警告 -->
        <div class="building-item"
             v-for="iter in item"
             :key="iter.id"
             :class="showWarning(iter.id)">
          <div @click="selectFloor(iter)" class="building-img">
            <img src="@/static/img/monitor/building.png" alt="">
            <img class="early-warning" src="@/static/img/monitor/early_warning.png" alt="">
          </div>
          <div @click="selectFloor(iter)" class="building-name">{{ iter.name }}</div>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>

  <div v-if="allBuildingData.value && allBuildingData.value.length === 1"
       class="building-list">
    <!-- class: warning-tips 警告 -->
    <div class="building-item"
         v-for="iter in allBuildingData.value[0]"
         :key="iter.id"
         :class="showWarning(iter.id)">
      <div @click="selectFloor(iter)" class="building-img">
        <img src="@/static/img/monitor/building.png" alt="">
        <img class="early-warning" src="@/static/img/monitor/early_warning.png" alt="">
      </div>
      <div @click="selectFloor(iter)" class="building-name">{{ iter.name }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BuildingListView',
  inject: {
    allBuildingData: {
      default: {}
    },
    warningEvents: {
      default: null
    }
  },
  computed: {
    floors(){
      if (this.warningEvents.value) {
        return this.warningEvents.value.floors || []
      }
      return []
    }
  },
  methods: {
    /**
     * @param id 当前id
     */
    showWarning(id){
      // this.floors.includes(id)
      return {
        'warning-tips': this.floors.includes(id)
      }
    },
    selectFloor(iter){
      this.$emit('selectFloor', iter)
    }
  }
}
</script>

<style lang="scss" scoped>
// 左右页
/deep/ .el-carousel__arrow--left, .el-carousel__arrow--right{
  background-color: transparent;
  background-image: url('../../../static/img/monitor/left-button.png');
  background-size: cover;
  i{
    display: none;
  }
}
/deep/ .el-carousel__arrow--right{
  background-color: transparent;
  background-image: url('../../../static/img/monitor/right-button.png');
  background-size: cover;
  i{
    display: none;
  }
}
// 导航点
/deep/ .el-carousel__button{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #364F61;
}
/deep/ .is-active .el-carousel__button{
  background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
}

// 椭圆
@keyframes ellipseAnimate {
  0% {
    transform: translateX(-50%) scale(0);
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0.7);
  }
  70% {
    transform: translateX(-50%) scale(1);
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0.7);
  }
  100% {
    transform: translateX(-50%) scale(1.2);
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0);
  }
}
// 动画调用类
// 警告牌显示
.warning-tips{
  .early-warning{
    display: block!important;
  }
  .building-img{
    &::after, &::before{
      animation-name: ellipseAnimate;
    }
  }
}

.building-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .building-item {
    width: 482px;
    height: 321px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .building-img {
      width: 200px;
      height: 222px;
      position: relative;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 222px;
        z-index: 20;
      }

      &::after, &::before {
        z-index: 10;
        position: absolute;
        left: 100px;
        transform: translateX(-50%);
        top: 50%;
        content: ' ';
        display: flex;
        width: 300px;
        height: 160px;
        border-radius: 50%;

        opacity: 0.5;
        box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0);

        animation-iteration-count: infinite;
        animation-duration: 1.5s;
        // 用warning-tips类激活
        //animation-name: ellipseAnimate;
        animation-timing-function: linear;
      }
      &::before{
        animation-delay: 0.5s;
      }
      // 警告标签
      .early-warning{
        display: none;
        position: absolute;
        top: 50px;
        left: -12px;
        width: 45px;
        height: 96px;
      }
    }

    .building-name {
      font-size: 24px;
      font-family: YouSheBiaoTiHei-Bold;
      color: #FFFFFF;
      line-height: 32px;
      margin-top: 6px;
      letter-spacing: 1px;
    }
  }
}
</style>