<template>
  <div>
    <div class="vis-card vis-card-1 flex flex-direction">
      <div :style="'background-image: url(' + monitorHeadShortBg + ');background-size: cover'" class="vis-card-title"
        style="cursor:default;">
        <div>数据概览</div>
      </div>
      <div class="vis-card-1-content flex-1 flex justify-around align-center">
        <div class="vis-card-1-item-outer">
          <div class="flex flex-direction vis-card-1-item">
            <span>{{ indexDataView.laboratorySize }}</span>
            <img src="@/static/img/monitor/smallBase.png" alt="">
          </div>
          <div>实验室数量</div>
        </div>
        <div class="vis-card-1-item-outer">
          <div class="flex flex-direction vis-card-1-item">
            <span>{{ indexDataView.cameraSize }}</span>
            <img src="@/static/img/monitor/smallBase.png" alt="">
          </div>
          <div>摄像头数量</div>
        </div>
        <div class="vis-card-1-item-outer">
          <div class="flex flex-direction align-center vis-card-1-item">
            <span>{{ percentageFormat(indexDataView.cover) }}%</span>
            <img src="@/static/img/monitor/smallBase.png" alt="">
          </div>
          <div style="white-space: nowrap;">摄像头覆盖率</div>
        </div>
      </div>
    </div>
    <div class="vis-card vis-card-2 flex flex-direction">
      <div :style="'background-image: url(' + monitorHeadShortBg + ');background-size: cover'" class="vis-card-title"
        style="cursor:default;">
        <div>预警概况</div>
      </div>
      <div class=" flex-1 flex flex-wrap align-center">
        <!-- >4 -->
        <el-carousel v-if="warningEventTypeCount.length > 1"
                     style="height: 100%;width: 100%;"
                     height="100%"
                     arrow="never"
                     :interval="4000"
                     :autoplay="true">
          <el-carousel-item v-for="(item, index) in warningEventTypeCount" :key="index">
            <div class="overview-early-warning" style="width: 100%;height: 100%;">
              <div v-for="(iter, index) in item" :key="index" @click="warningInfo(iter.id)"
                   class="flex align-center justify-center" style="cursor: pointer;">
                <div>
                  <img :src="imgList(String(iter.id))" alt="">
                </div>
                <div class="marginLeft10 flex flex-direction text-left">
                  <div class="fontSize14" style="color: #B4C0CC;">{{ iter.name }}</div>
                  <div class="fontSize20 marginTop8px">{{ iter.size }}</div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <=4 -->
        <div v-if="warningEventTypeCount.length === 1"
             class="overview-early-warning" style="width: 100%;height: 100%;">
          <div v-for="(iter, index) in warningEventTypeCount[0]" :key="index"
               @click="warningInfo(iter.id)"
               class="flex align-center justify-center"
               style="cursor: pointer;">
            <div>
              <img :src="imgList(String(iter.id))" alt="">
            </div>
            <div class="marginLeft10 flex flex-direction text-left">
              <div class="fontSize14" style="color: #B4C0CC;">{{ iter.name }}</div>
              <div class="fontSize20 marginTop8px">{{ iter.size }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vis-card vis-card-3 flex flex-direction">
      <div :style="'background-image: url(' + monitorHeadShortBg + ');background-size: cover'" class="vis-card-title"
        style="cursor:default;">
        <div>预警占比</div>
      </div>
      <div class="flex-1 flex flex-direction vis-card-3-container">
        <div v-for="(iter, index) in indexDataView.warningEventTypeCount" :key="index" class="event-item">
          <div class="flex justify-between align-center">
            <div class="fontSize14">{{ iter.name }}</div>
            <div style="color: #F5E74F;">
              <span>{{ iter.size }}</span>
              <span class="marginLeft5">({{ percentageFormat(iter.ratio) }}%)</span>
            </div>
          </div>
          <div class="marginTop10px">
            <el-progress :show-text="false" :stroke-width="16" :percentage="percentageFormat(iter.ratio)" />
          </div>
        </div>
      </div>
    </div>
    <!-- class: show-warning-tips 警告 -->
    <div class="vis-card vis-card-4 " :class="showWarning" id="visCard4">
      <building-list-view v-if="!laboratoryListShow" @selectFloor="selectFloor"></building-list-view>
      <laboratory-list v-if="laboratoryListShow"
                       @viewMonitoring="showMonitorDialog"
                       @closeLaboratoryList="laboratoryListShow = false"></laboratory-list>
    </div>
    <div class="vis-card vis-card-5 flex flex-direction">
      <div :style="'background-image: url(' + monitorHeadBg + ');background-size: cover'"
           class="vis-card-title justify-between">
        <div class="flex" style="cursor: pointer;"
             @click.stop="$router.push('/visualization/video/monitor')">
          <div>视频监控</div>
          <img src="@/static/img/monitor/right.png" alt="">
        </div>
        <div class="carousel-start-action flex align-center">
          <div class="image-box">
            <img v-show="!playing" src="@/static/img/monitor/suspend.png" alt="" @click="bannerSeting">
            <img v-show="playing" src="@/static/img/monitor/playing.png" alt="" @click="stopCarousel">
          </div>
        </div>
      </div>
      <div class="vis-card-5-container border-box padding20 flex-1 flex flex-direction">
        <div class="widthFull flex justify-between" style="height: 100%;">
          <div v-for="(iter, index) in videoSurveillance" :key="iter.id" class="vis-card-5-item" @click="showMonitorDialog(iter)">
            <div class="cover-box marginBottom12" :id="playerList[index] && playerList[index].elementId"></div>
            <div>{{playerList[index] && playerList[index].name }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="vis-card vis-card-6">
      <div :style="'background-image: url(' + monitorHeadShortBg + ');background-size: cover'" class="vis-card-title"
        @click="$router.push('/visualization/warning/list')">
        <div>预警档案</div>
        <img src="@/static/img/monitor/right.png" alt="">
      </div>
      <div class="main text-left border-box">
        <div class="main-item border-box flex align-center justify-between " :class="{ 'changeBg': !item.state }"
          v-for="(item, index) in warningEventList" :key="index" @click="eventDetail(item)">
          <div class="main-item-left">
            <img :src="projectName + item.imgPath" alt="">
          </div>
          <div class="main-item-center flex">
            <div class="main-item-center-title">{{ item.warningEventType.name }}</div>
            <div class="flex align-center"><img src="../../static/img/monitor/address.png" alt="">{{ item.building.name }}
            </div>
            <div class="flex align-center"><img src="../../static/img/monitor/surface.png" alt="">{{ item.time }}
            </div>
          </div>
          <div class="main-item-right flex align-center justify-between main-item-center-title">
            {{ item.state ? "已解除" : "预警中" }}
          </div>
        </div>
      </div>
    </div>
    <div class="vis-card vis-card-7">
      <div :style="'background-image: url(' + monitorHeadShortBg + ');background-size: cover'" class="vis-card-title"
        style="cursor:default;">
        <div>预警趋势</div>
      </div>
      <div class="main border-box" ref="trendEcharts"></div>
    </div>
    <monitor-dialog ref="monitorDialog" @close="monitorDialogClose"></monitor-dialog>
    <overview-dialog v-if="warning_dialog_visible" @close="warningDialogClose" ref="warningDialogRef"></overview-dialog>
    <monitor-detail :eventRow="eventRow" v-if="monitor_detail_visible" @close="monitorDetailClose"></monitor-detail>
    <!-- 轮播设置 -->
    <banner-seting v-if="bannerSetingVisible"
                   :show-mode="false"
                   @open="startCarousel"
                   @close="bannerSetingEvent"></banner-seting>

  </div>
</template>

<script>

import * as echarts from 'echarts';
import { warningTrendEcharts } from "./echarts/warningTrendEcharts"
import monitorDialog from "@/view/visualization/components/monitorDialog.vue";
import {
  getAllBuilding,
  queryIndexDataView,
  queryWarningEvent,
  warningEventPage
} from "@/api/monitor";
import { computed } from "vue";
import LaboratoryList from "@/view/visualization/components/LaboratoryList";
import BuildingListView from "@/view/visualization/components/BuildingList";
import overviewDialog from "@/view/visualization/components/overviewDialog.vue";
import { projectName } from "@/utils/config";
import monitorStore, {debounce, FlvPlayer, messageSocket} from "@/utils/monitor";
import monitorDetail from "@/view/visualization/components/monitorDetail.vue";
import bannerSeting from "@/view/visualization/components/bannerSeting.vue"

const arrIntercept = (arr, count = 24) => {
  const maxLength = count;
  const result = [];
  for (let i = 0; i < arr.length; i += maxLength) {
    result.push(arr.slice(i, i + maxLength));
  }
  return result;
}

export default {
  name: 'visualizationIndex',
  components: { BuildingListView, LaboratoryList, monitorDialog, overviewDialog, monitorDetail, bannerSeting },
  data() {
    return {
      projectName,
      // -
      // 楼栋类别
      allBuildingData: [],
      // 数据概览，预警概况，预警占比，预警趋势
      indexDataView: {},
      // 视频监控
      videoSurveillance: [],
      // - i
      // 楼栋内的实验室列表
      laboratoryList: [],
      floorName: '',
      laboratoryListShow: false,
      // -- require
      monitorHeadBg: require('@/static/img/monitor/monitorHeadBg.png'),
      monitorHeadShortBg: require('@/static/img/monitor/titleBg.png'),
      // 预警档案
      warningEventList: [],
      // 预警概况dialog
      warning_dialog_visible: false,
      //
      warningEvents: '',
      // 视频监控轮播定时器
      videoSurveillanceCarouselTimer: null,
      // ------档案详情
      eventRow: null,
      monitor_detail_visible: false,
      //
      playing: false,
      bannerSetingVisible: false,
      playerList: [],
    }
  },
  computed:{
    /**
     * 预警概况
     */
    warningEventTypeCount(){
      let res = []
      if (Reflect.has(this.indexDataView, 'warningEventTypeCount')) {
        res = arrIntercept(this.indexDataView.warningEventTypeCount, 4)
      }
      return res
    },
    /**
     * 显示警告框
     */
    showWarning(){
      if (this.warningEvents) {
        return {
          'show-warning-tips': this.warningEvents.floors.length > 0 || this.warningEvents.laboratory.length > 0
        }
      }
      return { }
    }
  },
  watch: {
    /**
     * 监听首页预警处理事件, 处理后刷新楼栋效果
     */
    'warningHandlerId.value': {
      handler(val){
        if (val) {
          // 中间预警刷新
          this.getWarningEventList()
          // 右侧预警档案刷新
          this.getAllEventList()
        }
      }
    }
  },
  inject: {
    warningHandlerId: {
      default: null
    }
  },
  provide() {
    return {
      // i 4
      allBuildingData: computed(() => this.allBuildingData),
      laboratoryList: computed(() => this.laboratoryList),
      floorName: computed(() => this.floorName),
      warningEvents: computed(() => this.warningEvents)
      // -i 5
    }
  },
  mounted() {
    // -
    this.getAllBuildingData()
    this.getIndexDataView()
    this.getAllEventList()
    // 正在预警的事件
    monitorStore.getElasticLayerQueue().push('getWarningLayer')
    const getWarningEventList = debounce(this.getWarningEventList, 300)
    getWarningEventList()
    const io = new IntersectionObserver(getWarningEventList)
    this.$nextTick(() => {
      io.observe(document.getElementById('visCard4'))
    })
    // 监听推送消息更新预警档案
    messageSocket.setReceiveMessageHandler('indexUpdateWarningFile', ({ type }) => {
      // 右侧预警档案刷新
      type === 'warning_event' && this.getAllEventList()
    })
  },
  onBeforeUnmount() {

  },
  beforeRouteLeave(to, form){
    if (this.date_sh) {
      clearInterval(this.date_sh)
    }
    // 视频监控轮播
    clearInterval(this.videoSurveillanceCarouselTimer)
    //
    this.destructionAll()
  },
  methods: {
    // -
    imgList(key){
      const imgList = {
        '1': { url: require("@/static/img/monitor/fight.png") },
        '2': { url: require("@/static/img/monitor/region.png") },
        '3': { url: require("@/static/img/monitor/gather.png") },
        '4': { url: require("@/static/img/monitor/wander.png") },
        '5': { url: require("@/static/img/monitor/retention.png") },
        '6': { url: require("@/static/img/monitor/climb.png") },
        '7': { url: require("@/static/img/monitor/viewWarn.png") },
        '8': { url: require("@/static/img/monitor/fall.png") },
      }
      const item = imgList[key]
      if (item) {
        return item.url
      }
      // default
      return require("@/static/img/monitor/region.png")
    },
    /**
     * 获取正在预警的列表
     */
    getWarningEventList(){
      if (monitorStore.getElasticLayerQueue().getPrev() === 'closeWarningLayer') {
        monitorStore.getElasticLayerQueue().push('getWarningLayer')
        return
      }

      queryWarningEvent().then(res => {
        const data = {
          floors: [],
          laboratory: [],
        }
        if (res.success) {
          res.data.forEach(iter => {
            data.floors.push(iter.buildingId)
            iter.settings.forEach(item => {
              data.laboratory.push(item.laboratory.id)
            })
          })
        }
        this.warningEvents = data
        monitorStore.setWarningEvent(res)
      })
    },
    /**
     * 销毁所有播放
     */
    destructionAll(){
      this.playerList.map(iter => {
        iter.flvPlayer && iter.destruction()
        iter.flvPlayer = null
      })
      this.playerList = []
      this.playerList.length = 0
    },
    /**
     * 获取所有楼栋
     */
    getAllBuildingData() {
      /**
       * 随机轮播处理
       * @param res
       */
      const randomCarouselHandler = res => {
        const allLaboratoryList = []
        res.data.forEach(iter => {
          iter.laboratoryList.map(item => {
            item.parentName = iter.name
          })
          iter.laboratoryList.forEach(item => {
            if (item.equipmentList.length > 0) {
              allLaboratoryList.push(item)
            }
          })
        })

        const shuffleArray = arr => {
          let len = arr.length;
          for (let i = 0; i < len - 1; i++) {
            let index = parseInt(Math.random() * (len - i));
            let temp = arr[index];
            arr[index] = arr[len - i - 1];
            arr[len - i - 1] = temp;
          }
          return arr;
        }

        shuffleArray(allLaboratoryList)
        const videoSurveillance = allLaboratoryList.slice(0, 4)
        // 测试
        // const item = allLaboratoryList.find(iter => iter.id === 82)
        // item && this.videoSurveillance.push(item)

        // 播放列表 this.playerList
        // 清空上一组
        this.destructionAll()
        // 生成当前组
        videoSurveillance.forEach(iter => {
          const item = iter.equipmentList[0]
          const flvPlayer = new FlvPlayer(item.id, `carouselVideoId-${item.id}`)
          flvPlayer.name = item.name
          flvPlayer.setVolume(0)
          flvPlayer.setShowScreenshot(false)
          this.playerList.push(flvPlayer)
          this.$nextTick(() => flvPlayer.play())
        })
        // console.log(this.playerList, '--------this.playerList-');
        //
        this.videoSurveillance = videoSurveillance
        // console.log(videoSurveillance, '--------this.videoSurveillance-');
      }

      //
      getAllBuilding().then(res => {
        this.allBuildingData = arrIntercept(res.data, 4)
        // 选择随机视频监控
        randomCarouselHandler(res)
        // 定时轮播 等待手动开启
        this.randomCarouselHandler = intervalTime => {
          this.playing = true
          const frequency = parseInt(intervalTime)
          // 先清空
          this.videoSurveillanceCarouselTimer && clearInterval(this.videoSurveillanceCarouselTimer)
          // 定时器
          this.videoSurveillanceCarouselTimer = setInterval(() => {
            randomCarouselHandler(res)
          }, frequency * 1000)
        }
      })
    },
    /**
     * 数据概览，预警概况，预警占比，预警趋势
     */
    getIndexDataView() {
      queryIndexDataView().then(res => {
        this.indexDataView = res.data
        //
        const warningEventTrend = this.indexDataView.warningEventTrend
        const date = []
        const data = []
        warningEventTrend.slice(0, 7).reverse().forEach(iter => {
          date.push(iter.day)
          data.push(iter.size)
        })
        this.drawWarningTrend(date, data)
      })
    },
    /**
     * 预警趋势
     */
    drawWarningTrend(date, data) {
      let title = "7"
      const trendEcharts = this.$refs["trendEcharts"]
      const myChart = echarts.init(trendEcharts)
      myChart.setOption(warningTrendEcharts(date, data, title))
    },
    /**
     *
     */
    percentageFormat(val) {
      const value = val * 100
      return isNaN(value) ? 0 : value.toFixed(2)
    },
    // - e
    /**
     * 选择楼层
     */
    selectFloor(iter) {
      // 当前选择楼
      this.floorName = iter.name
      // 当前选择实验室
      this.laboratoryList = arrIntercept(iter.laboratoryList)
      // 预览实验室
      this.laboratoryListShow = true
    },
    // --
    /**
     * 开启弹层
     */
    showMonitorDialog(iter) {
      if (iter.equipmentList.length === 0) {
        return
      }
      this.$refs.monitorDialog.open(iter.equipmentList)
    },
    /**
     * 关闭弹层
     */
    monitorDialogClose() {

    },
    // ---档案详情
    // 详情
    eventDetail(row) {
      this.eventRow = row
      this.monitor_detail_visible = true
    },
    monitorDetailClose() {
      this.monitor_detail_visible = false
      this.getAllEventList()
      monitorStore.getElasticLayerQueue().push('getWarningLayer')
      this.getWarningEventList()
    },
    // 获取预警档案
    async getAllEventList() {
      let { data } = await warningEventPage({ limit: 5, page: 1 })
      this.warningEventList = data.records
      console.log(this.warningEventList);
    },
    async warningInfo(id) {
      // console.log(id);
      this.warning_dialog_visible = true
      this.$nextTick(() => {
        this.$refs.warningDialogRef.getOverviewData(id)
      })
    },
    warningDialogClose() {
      this.warning_dialog_visible = false
    },
    //
    /**
     * 停止轮播
     */
    stopCarousel(){
      // 标记开启轮播
      this.playing = false
      clearInterval(this.videoSurveillanceCarouselTimer)
    },
    /**
     * 开启轮播
     * @param intervalTime 间隔时间 3
     * @param carouselMode 轮播模式 随机|顺序
     */
    startCarousel({ intervalTime }){
      if (Reflect.has(this, 'randomCarouselHandler')) {
        // 关闭轮播设置弹层
        this.bannerSetingVisible = false
        // 开启轮播
        this.randomCarouselHandler(intervalTime)
      }
    },
    /**
     * 开启轮播窗口
     */
    bannerSeting(){
      this.bannerSetingVisible = true
    },
    /**
     * 关闭设置窗口
     */
    bannerSetingEvent() {
      this.bannerSetingVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.changeBg {
  background: url(../../static/img/monitor/warnBg.png) no-repeat !important;

  .main-item-center-title {
    color: #FF386B !important;
  }

  .main-item-right {
    background: url(../../static/img/monitor/danger.png) no-repeat !important;
    background-position: center 0 !important;
  }
}

.border-box {
  box-sizing: border-box;
}

.text-left {
  text-align: left;
}

.event-item {
  /deep/ .el-progress-bar__outer {
    background: rgba(255, 255, 255, 0.1);
  }

  /deep/ .el-progress-bar__inner {
    background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
  }
}

// 动画定义
// 矩形
@keyframes warningTips {
  0% {
    box-shadow: inset 0 0 25px 0 #ffebf000;
  }

  100% {
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0.4);
  }
}

// 动画调用类
// 警告框
.show-warning-tips {
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-name: warningTips;
  animation-direction: alternate;
}

.vis-header-warp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background-color: #053552;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .left {
    width: 480px;
    display: flex;
    justify-content: space-between;

    .logo {
      margin-left: 40px;
    }

    .warning-icon {
      margin-right: 90px;
      cursor: pointer;
    }
  }

  .center {
    margin-top: 13px;
    width: 960px;

    span {
      letter-spacing: 8px;
      font-size: 44px;
      font-family: YouSheBiaoTiHei-Bold;
      background-image: linear-gradient(180deg, #FFFFFF 0%, #009DFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .right {
    width: 480px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      margin-left: 30px;
      font-size: 18px;
      line-height: 60px;

      .date {
        color: #009DFF;
        font-weight: normal;
      }

      .time,
      .week {
        font-weight: normal;
      }
    }

    .action {
      margin-right: 40px;

      img {
        cursor: pointer;
      }

      img+img {
        margin-left: 20px;
      }
    }
  }

  span {
    cursor: pointer;
  }
}

.vis-card {
  background: rgba(5, 86, 132, 0.2);
}

.vis-card-title {
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 50px;

  font-size: 24px;
  line-height: 24px;
  color: #FFFFFF;
  cursor: pointer;

  .carousel-start-action{
    height: 100%;
    padding-right: 20px;
    .image-box {
      width: 40px;
      height: 30px;
      background: #0F2B3F;
      border-radius: 4px;

      img {
        margin: unset;
        width: 28px;
        height: 28px;
        object-fit: cover;
      }
    }
  }

  div {
    font-family: YouSheBiaoTiHei-Bold;
  }

  img {
    margin-left: 8px;
    width: 24px;
    height: 24px;
  }
}

.vis-card-1 {
  position: absolute;
  left: 24px;
  top: 110px;
  width: 430px;
  height: 210px;

  .vis-card-1-content {
    .vis-card-1-item-outer {
      div {
        font-size: 16px;
        color: #FFFFFF;
      }

      .vis-card-1-item {
        margin-bottom: 12px;
        position: relative;
        height: 90px;

        span {
          font-size: 28px;
          font-family: YouSheBiaoTiHei-Bold;
          color: #F5E74F;
        }

        img {
          bottom: 0;
          position: absolute;
          width: 80px;
        }
      }
    }
  }
}

.vis-card-2 {
  position: absolute;
  left: 24px;
  top: 344px;


  width: 430px;
  height: 260px;

  // 导航点
  /deep/ .el-carousel__button{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #364F61;
  }

  /deep/ .is-active .el-carousel__button{
    background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
  }

  .overview-early-warning {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

.vis-card-3 {
  position: absolute;
  left: 24px;
  top: 628px;

  width: 430px;
  height: 428px;
  box-sizing: border-box;

  .vis-card-3-container {
    padding-top: 20px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none
    }

    .event-item {
      margin: 0 auto 22px;
      width: 390px;
      height: 50px;
      flex-shrink: 0;
    }
  }
}

.vis-card-4 {
  position: absolute;
  left: 478px;
  top: 110px;

  width: 964px;
  height: 642px;
}

.vis-card-5 {
  position: absolute;
  left: 478px;
  top: 776px;

  width: 964px;
  height: 280px;

  .vis-card-5-container {
    .vis-card-5-item {
      width: 216px;
      height: 190px;
      cursor: pointer;

      .cover-box {
        position: relative;
        width: 216px;
        height: 154px;
        border: 1px solid #59FFFF;

        .cover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0.5;
        }

        .play {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }

    }
  }
}

.vis-card-6 {
  position: absolute;
  left: 1466px;
  top: 110px;

  width: 430px;
  height: 642px;

  .main {
    padding: 20px;
    height: calc(100% - 50px);

    &-item {
      height: 100px;
      background: url(../../static/img/monitor/smallBaseClose.png) no-repeat;
      background-size: cover;
      padding: 10px;
      margin-bottom: 12px;

      &-left {
        >img {
          width: 80px;
          height: 80px;
          position: relative;
          top: 3px;
          margin-right: 10px;
        }
      }

      &-center {
        flex: 1;
        flex-direction: column;
        justify-content: space-around;
        font-size: 14px;

        :first-child {
          color: #59FFFF;
          font-size: 18px;
        }

        :nth-child(2) {
          margin: 8px 0 2px 0;

          >img {
            margin-right: 3px;
          }
        }

        :last-child {
          >img {
            margin-right: 3px;
          }
        }
      }

      &-right {
        width: 42px;
        height: 50px;
        margin-right: 15px;
        flex-direction: column;
        justify-content: end;
        color: #59FFFF;
        font-size: 14px;
        background: url(../../static/img/monitor/auth.png) no-repeat;
        background-position: center 0;
      }
    }
  }
}

.vis-card-7 {
  position: absolute;
  left: 1466px;
  top: 776px;

  width: 430px;
  height: 280px;

  .main {
    height: calc(100% - 50px);
    padding: 10px;
  }
}
</style>