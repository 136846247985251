<template>
  <div class="laboratory-list room-list flex flex-direction">
    <div class="vis-card-4-title flex align-center marginLeft20">
      <div class="flex" style="cursor: pointer;" @click="closeLaboratoryList">
        <img src="@/static/img/monitor/back.png" alt="">
        <span class="marginLeft12">{{ floorName.value }}</span>
      </div>
    </div>
    <div class="vis-card-4-title-container flex-1">
      <el-carousel style="height: 100%;"
                   height="100%"
                   :interval="5000"
                   arrow="always"
                   :autoplay="false">
        <el-carousel-item v-for="(item, index) in laboratoryList.value" :key="index">
          <div class="room-container">
            <!-- 预警样式 -->
            <!--<div class="room-item-warning room-item flex justify-center align-center">133</div>-->
            <div class="room-item flex justify-center align-center"
                 v-for="iter in item" :key="iter.id"
                 :class="showWarning(iter.id)"
                 @click="viewMonitoring(iter)">
              <div class="room-item-name">{{ iter.name }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LaboratoryList',
  inject: {
    laboratoryList: {
      default: {}
    },
    floorName: {
      default: {}
    },
    warningEvents: {
      default: null
    }
  },
  computed: {
    laboratory(){
      if (this.warningEvents.value) {
        return this.warningEvents.value.laboratory || []
      }
      return []
    }
  },
  mounted() {

  },
  methods: {
    /**
     * @param id 当前id
     */
    showWarning(id){
      // this.floors.includes(id)
      return {
        'room-item-warning': this.laboratory.includes(id)
      }
    },
    closeLaboratoryList(){
      this.$emit('closeLaboratoryList')
    },
    /**
     * 查看监控
     */
    viewMonitoring(iter){
      this.$emit('viewMonitoring', iter)
    }
  }
}
</script>

<style lang="scss" scoped>
// 椭圆
@keyframes ellipseAnimate {
  0% {
    transform: translateX(-50%) scale(0);
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0.7);
  }
  70% {
    transform: translateX(-50%) scale(1);
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0.7);
  }
  100% {
    transform: translateX(-50%) scale(1.2);
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0);
  }
}

// 警告样式
.room-item-warning{
  background-image: url('../../../static/img/monitor/laboratoryBgWarning.png')!important;

  &::after{
    box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0.7)!important;
    animation-name: ellipseAnimate;
  }
}

.laboratory-list{

}
.room-list{
  height: 100%;
  .vis-card-4-title{
    height: 82px;
    span{
      font-size: 24px;
      font-family: YouSheBiaoTiHei-Bold;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
    }
    img{
      width: 24px;
      height: 24px;
    }
  }
  .vis-card-4-title-container{
    // 左右页
    /deep/ .el-carousel__arrow--left, .el-carousel__arrow--right{
      background-color: transparent;
      background-image: url('../../../static/img/monitor/left-button.png');
      background-size: cover;
      i{
        display: none;
      }
    }
    /deep/ .el-carousel__arrow--right{
      background-color: transparent;
      background-image: url('../../../static/img/monitor/right-button.png');
      background-size: cover;
      i{
        display: none;
      }
    }
    // 导航点
    /deep/ .el-carousel__button{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #364F61;
    }
    /deep/ .is-active .el-carousel__button{
      background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
    }

    .room-container{
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 60px;

      margin: 0 auto;
      width: 780px;
      height: 500px;

      .room-item{
        position: relative;
        width: 80px;
        height: 80px;
        background-image: url('../../../static/img/monitor/laboratoryBg.png');
        background-size: cover;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 5px;
        box-sizing: border-box;

        .room-item-name{
          overflow: hidden;
          word-break: break-all;  /* break-all(允许在单词内换行。) */
          text-overflow: ellipsis;  /* 超出部分省略号 */
          display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
          -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
          -webkit-line-clamp: 3; /** 显示的行数 **/
        }

        &::after {
          z-index: 10;
          position: absolute;
          left: 40px;
          transform: translateX(-50%);
          top: 20%;
          content: ' ';
          display: flex;
          width: 120px;
          height: 70px;
          border-radius: 50%;

          opacity: 0.5;
          box-shadow: inset 0 0 25px 21px rgba(255, 56, 107, 0);

          animation-iteration-count: infinite;
          animation-duration: 1.5s;
          // 用warning-tips类激活
          //animation-name: ellipseAnimate;
          animation-timing-function: linear;
        }
      }
    }
  }
}
</style>