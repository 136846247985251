<template>
  <el-dialog v-model="small_dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-small-dialog"
    :close-on-click-modal="false" :close-on-press-escape="false" align-center :show-close="false" :lock-scroll="false"
    :style="'background-image: url(' + monitor_dialog_small_bg + ');background-size: cover'">
    <template #header>
      <div class="monitor-dialog-headers">
        <div class="monitor-dialog-title">轮播设置</div>
        <div class="monitor-dialog-close" @click="close()"></div>
      </div>
    </template>
    <div class="monitor-dialog-contents">
      <div class="main flex">
        <div class="main-top flex">
          <div class="frequency">
            <span>轮播频率：</span>
            <input v-model.number="intervalTime" @blur="integerParse(intervalTime, val => intervalTime = val)">
            <span style="margin-left: 8px;">s</span>
          </div>
          <div class="way flex align-center" v-if="showMode">
            <span>轮播方式：</span>
            <el-radio-group v-model="carouselMode" class="ml-4">
              <el-radio :label="1" size="large">
                <template #default>
                  <span style="color: #80CEFF;">顺序轮播</span>
                </template>
              </el-radio>
              <el-radio :label="2" size="large">
                <template #default>
                  <span style="color: #80CEFF;">随机轮播</span>
                </template>
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="main-footer">
          <button @click="close">取消</button>
          <button @click="submit">确定</button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {integerParse} from "@/utils/common";

export default {
  name: 'ScreenshotShow',
  props: {
    showMode: {
      type: Boolean,
      default(){
        return true
      }
    }
  },
  data() {
    return {
      radio1: 1,
      monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
      small_dialog_visible: true,
      monitor_dialog_small_bg: require('@/static/img/monitor/monitor-dialog-small-bg.png'),
      // 轮播设置
      intervalTime: '',
      carouselMode: 1,
    }
  },
  methods: {
    integerParse,
    close() {
      this.$emit('close')
    },
    /**
     * 开启轮播
     */
    submit(){
      const intervalTime = this.intervalTime
      if (intervalTime > 0) {
        this.$emit('open', {
          intervalTime: intervalTime,
          carouselMode: this.carouselMode,
        })
      }
    },
  }
}
</script>

<style lang="scss">
.monitor-small-dialog {
  --el-dialog-width: 800px;
  height: 450px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}
.el-radio__input.is-checked .el-radio__inner {
  background-color: #80CEFF !important;
  border-color: #80CEFF !important;
}
.el-radio__inner {
  background-color: transparent !important;
  border-color: #80CEFF !important;
}
</style>

<style scoped lang="scss">
.monitor-dialog-header {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-content {
  width: 100%;
  height: calc(100% - 64px);
  padding: 10px 0 30px 20px;
  box-sizing: border-box;
  display: flex;

  .detail {
    width: 320px;
    height: 696px;
    background-color: #001F32;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;

    &-top {
      font-size: 14px;
      color: #80CEFF;

      >div {
        display: flex;

        >div {
          display: inline-block;
          width: 70px;
          text-align: right;
        }

        margin-bottom: 16px;

        >span {
          display: inline-block;
          width: calc(100% - 70px);
          color: #fff;
          text-align: left;
        }
      }
    }

    &-bottom {

      >button {
        display: block;
        width: 160px;
        height: 40px;
        border-radius: 20px;
        font-size: 14px;
        cursor: pointer;
        margin: auto;
      }

      :first-child {
        border: 1px solid #FF386B;
        background: rgba(255, 56, 107, 0.1);
        color: #FF386B;
        margin-bottom: 20px;
      }

      :last-child {
        border: 1px solid #009DFF;
        background: rgba(0, 157, 255, 0.1);
        color: #009DFF;
      }
    }
  }

  .main {
    width: 1020px;
    height: 696px;
    margin-right: 20px;
    background: #ff5a5f;
  }
}

.monitor-dialog-headers {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-contents {
  .main {
    flex-direction: column;
    padding: 20px 40px;
    &-top {
      margin-top: 10px;
      align-items: flex-start;
      flex-direction: column;

      >span {
        color: #80CEFF;
        font-size: 14px;
      }

      >textarea {
        resize: none;
        box-sizing: border-box;
        width: 480px;
        height: 248px;
        background: #001F32;
        border-radius: 5px 5px 5px 5px;
        color: #fff;
        outline: none;
        border: none;
        padding: 9px 12px;

        // overflow-y: hidden;
        &::placeholder {
          color: #B4C0CC;
          font-size: 14px;
        }
      }

      .way,
      .frequency {
        >span {
          color: #80CEFF;
          font-size: 14px;
        }
      }

      .frequency {
        margin-bottom: 20px;

        >input {
          color: #fff;
          outline: none;
          width: 160px;
          height: 40px;
          background-color: #001F32;
          border: none;
        }
      }

      .way {
        >div {
          >span {
            color: #80CEFF;
            font-size: 14px;
            margin-left: 2px;
          }
        }

      }
    }

    &-footer {
      margin-top: 170px;
      // position: absolute;
      // bottom: 30px;
      >button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
      }

      :first-child {
        border: 1px solid #009DFF;
        background: rgba(0, 157, 255, 0.1);
        color: #B4C0CC;
        margin-right: 40px;
      }

      :last-child {
        border: 1px solid #009DFF;
        background: #009DFF;
        color: #fff;
      }
    }
  }
}
</style>