import * as echarts from 'echarts';

export const warningTrendEcharts = (date, data,title)=> {
    return {
		title:{
			text: title !== "30" ? "近7天预警趋势" : '',
			left:"3.5",
			top:5,
			textStyle:{
				color:"#fff",
			    fontSize:14
			}
		},
		backgroundColor: "",
		grid: {
			top: '20%',
			left: '2%',
			right: '5%',
			bottom: '5%',
			containLabel: true,
		},
		xAxis: [{
			type: 'category',
			boundaryGap: false,
			axisLine: { //坐标轴轴线相关设置。数学上的x轴
				show: true,
				lineStyle: {
					color: '#233e64'
				},
			},
			axisLabel: { //坐标轴刻度标签的相关设置
				interval:title !== "30" ? 0 : 3,
				// width:,
				rotate:20,
				textStyle: {
					color: '#B4C0CC',
					margin:15,
					fontSize:10
				},
				margin:15,
			 },
			 axisTick: { show: false,},
			// data: ['11-24', '11-24', '11-24', '11-24', '11-24', '11-24', '11-24'],
			data: date,
		}],
		yAxis: [{
			type: 'value',
			min: 0,
			// max:800,
			splitNumber: 4,
			splitLine: {
				 show: true,
				 lineStyle: {
					color: '#233e64',
					type: "dashed"
				 }
			 },
			 axisLine: {show: false,},
			 axisLabel: {
			 	margin:27,
				 textStyle: {
					color: '#B4C0CC',
				 },
			 },
			 axisTick: { show: false,},  
		}],
		series: [{
			name: '异常流量',
			type: 'line',
			smooth: false, //是否平滑曲线显示
// 			symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
			symbolSize:0,
			
			lineStyle: {
				width: 1,
				color: "#0277BF"   // 线条颜色
			},
			areaStyle: { //区域填充样式
                normal: {
                 //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
					   { offset: 0,  color: 'rgba(0,157,255, 0.9)'}, 
					   { offset: 1,  color: 'rgba(0,157,255, 0)'}
				   ], false),

                 shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
             }
         },
			// data: [500, 105, 600, 125, 300, 400, 200]
			data: data
		}]
    }
}
    