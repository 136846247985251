<template>
  <div>
    <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-dialog"
      :close-on-click-modal="false" :close-on-press-escape="false" align-center :show-close="false" :lock-scroll="false"
      :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
      <template #header>
        <div class="monitor-dialog-header">
          <div class="monitor-dialog-title">视频监控</div>
          <div class="monitor-dialog-close" @click="close"></div>
        </div>
      </template>
      <div class="monitor-dialog-content">
        <div class="main">
          <img :src="projectName + eventRow.imgPath" alt="">
        </div>
        <div class="detail flex justify-between">
          <div class="detail-top">
            <div>
              <div>事件类型：</div><span>{{ eventRow.warningEventType.name }}</span>
            </div>
            <div>
              <div>楼栋：</div><span>{{ eventRow.building.name }}</span>
            </div>
            <div>
              <div>地点：</div><span>{{ eventRow.laboratory.name }}</span>
            </div>
            <div>
              <div>预警时间：</div><span>{{ eventRow.time }}</span>
            </div>
            <div>
              <div>处理意见：</div><span>{{ eventRow.closeMsg }}</span>
            </div>
          </div>
          <div class="detail-bottom">
            <button v-if="!eventRow.state" @click="small_dialog_visible = true">解除预警</button>
            <button @click="Download">下载</button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="small_dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-small-dialog"
      :close-on-click-modal="false" :close-on-press-escape="false" align-center :show-close="false" :lock-scroll="false"
      :style="'background-image: url(' + monitor_dialog_small_bg + ');background-size: cover'">
      <template #header>
        <div class="monitor-dialog-headers">
          <div class="monitor-dialog-title">处理意见</div>
          <div class="monitor-dialog-close" @click="close('small')"></div>
        </div>
      </template>
      <div class="monitor-dialog-contents">
        <div class="main flex align-center">
          <div class="main-top flex">
            <span style="margin-top: 9px;">预置式选择分类：</span>
            <div>
              <el-select v-model="formData.warningEventOpinionId" :teleported="false" placeholder="请选择" size="large" style="width: 466px;" >
                <el-option v-for="item in warningProcessing" :key="item.id" :label="item.content" :value="item.id" />
              </el-select>
            </div>
          </div>
          <div class="main-top flex" v-show="selectedOther()" >
            <span style="margin-top: 9px;">处理意见：</span>
            <textarea v-model="formData.closeMsg" type="textarea" placeholder="请输入处理意见" maxlength=100></textarea>
          </div>
          <div class="main-footer">
            <button @click="close('small')">取消</button>
            <button @click="submit">确定</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
import {warningEventClose, warningEventOpinionAll} from "@/api/monitor"
import { projectName } from "@/utils/config"
export default {
  name: 'monitorDialog',
  props: {
    eventRow: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      projectName,
      monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
      dialog_visible: true,
      small_dialog_visible: false,
      monitor_dialog_small_bg: require('@/static/img/monitor/monitor-dialog-small-bg.png'),
      formData: {
        closeMsg: "",
        warningEventId: null,
        warningEventOpinionId: '',
      },
      //
      warningProcessing: [],
    }
  },
  mounted() {
    console.log(this.eventRow);
    this.formData.warningEventId = this.eventRow.id
    this.getWarningEventOpinionAll()
  },
  methods: {
    selectedOther(){
      const item = this.warningProcessing.find(iter => iter.id === this.formData.warningEventOpinionId)
      if (item) {
        return item.type === 1
      }
      return false
    },
    getWarningEventOpinionAll(){
      warningEventOpinionAll().then(res => {
        if (res.success) {
          this.warningProcessing = []
          Array.isArray(res.data) && res.data.forEach(iter => {
            const { content, type, id } = iter
            this.warningProcessing.push({ content, type, id  })
          })
        }
      })
    },
    close(val) {
      console.log(val);
      if (val == "small") {
        this.small_dialog_visible = false
        return
      }
      this.$emit('close')
    },
    monitorDetailClose() {
      this.small_dialog_visible = false
    },
    async submit() {
      let res = await warningEventClose(this.formData)
      if (res.code == 200) {
        this.small_dialog_visible = false
        this.$root.ElMessage.success("预警已解除")
        this.$emit('close')
      } else {
        this.$root.ElMessage.error(res.message)
      }
    },
    // 下载
    Download() {
      if (!this.eventRow || !this.eventRow.imgPath) return
      const temps = this.eventRow.imgPath.split('/')
      const ext = temps[temps.length - 1].split('.').slice(-1)[0]
      const name = `${this.eventRow?.laboratory?.name || '预警事件'}.${ext}`
      fetch(projectName + this.eventRow.imgPath)
        .then(res => res.blob())
        .then(blob => {
          const a = document.createElement('a')
          const objUrl = window.URL.createObjectURL(blob)
          a.download = name
          a.href = objUrl
          a.click()
        })
    },
  },
}
</script>
  
<style lang="scss">
.monitor-dialog {
  --el-dialog-width: 1420px;
  height: 800px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}

.monitor-small-dialog {
  --el-dialog-width: 800px;
  height: 450px;
  --el-dialog-bg-color: transparent;
  --el-box-shadow: none;

  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
  
<style scoped lang="scss">
.monitor-dialog-contents{

  --el-text-color-regular: #B4C0CC;
  --el-fill-color-blank: rgba(0, 0, 0, 0);
  --el-border-color: #009DFF;
  --el-border-color-hover: #009DFF;
  --el-select-input-focus-border-color: #009DFF;
  --el-color-primary: #009DFF;
  --el-select-border-color-hover: #009DFF;
  --el-input-focus-border-color: #009DFF;
  --el-input-hover-border-color: #009DFF;
  --el-text-color-disabled: #009DFF;
  // 下拉框背景
  --el-bg-color-overlay: #00000a;
  --el-fill-color-light: #001e38;

  --el-color-white: #ffffff;
  --el-color-black: #000000;
  --el-color-primary-light-3: #79bbff;
  --el-color-primary-light-5: #a0cfff;
  --el-color-primary-light-7: #c6e2ff;
  --el-color-primary-light-8: #d9ecff;
  --el-color-primary-light-9: #ecf5ff;
  --el-color-primary-dark-2: #337ecc;

  --el-bg-color: #ffffff;
  --el-bg-color-page: #f2f3f5;
  --el-text-color-primary: #303133;
  --el-text-color-secondary: #909399;
  --el-text-color-placeholder: #a8abb2;
  --el-border-color-light: #009DFF;
  --el-border-color-lighter: #009DFF;
  --el-border-color-extra-light: #009DFF;
  --el-border-color-dark: #009DFF;
  --el-border-color-darker: #009DFF;
  --el-fill-color: #009DFF;
  //--el-fill-color-lighter: #009DFF;
  --el-fill-color-extra-light: #009DFF;
  --el-fill-color-dark: #009DFF;
  --el-fill-color-darker: #009DFF;
  --el-box-shadow: 0px 12px 32px 4px rgba(0, 0, 0, 0.04), 0px 8px 20px rgba(0, 0, 0, 0.08);
  --el-box-shadow-light: 0px 0px 12px rgba(0, 0, 0, 0.12);
  --el-box-shadow-lighter: 0px 0px 6px rgba(0, 0, 0, 0.12);
  --el-box-shadow-dark: 0px 16px 48px 16px rgba(0, 0, 0, 0.08), 0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  --el-disabled-bg-color: var(--el-fill-color-light);
  --el-disabled-text-color: var(--el-text-color-placeholder);
  --el-disabled-border-color: var(--el-border-color-light);
  --el-overlay-color: rgba(0, 0, 0, 0.8);
  --el-overlay-color-light: rgba(0, 0, 0, 0.7);
  --el-overlay-color-lighter: rgba(0, 0, 0, 0.5);
  --el-mask-color: rgba(255, 255, 255, 0.9);
  --el-mask-color-extra-light: rgba(255, 255, 255, 0.3);
  --el-border-width: 1px;
  --el-border-style: solid;
  --el-border: var(--el-border-width) var(--el-border-style) var(--el-border-color);
  --el-svg-monochrome-grey: var(--el-border-color);
}

.monitor-dialog-header {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-content {
  width: 100%;
  height: calc(100% - 64px);
  padding: 10px 0 30px 20px;
  box-sizing: border-box;
  display: flex;

  .detail {
    width: 320px;
    height: 696px;
    background-color: #001F32;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;

    &-top {
      font-size: 14px;
      color: #80CEFF;

      >div {
        display: flex;

        >div {
          display: inline-block;
          width: 70px;
          text-align: right;
        }

        margin-bottom: 16px;

        >span {
          display: inline-block;
          width: calc(100% - 70px);
          color: #fff;
          text-align: left;
        }
      }
    }

    &-bottom {

      >button {
        display: block;
        width: 160px;
        height: 40px;
        border-radius: 20px;
        font-size: 14px;
        cursor: pointer;
        margin: auto;
      }

      :first-child {
        border: 1px solid #FF386B;
        background: rgba(255, 56, 107, 0.1);
        color: #FF386B;
        margin-bottom: 20px;
      }

      :last-child {
        border: 1px solid #009DFF;
        background: rgba(0, 157, 255, 0.1);
        color: #009DFF;
      }
    }
  }

  .main {
    width: 1020px;
    height: 696px;
    margin-right: 20px;

    >img {
      width: 100%;
      height: 100%;
    }
  }
}

.monitor-dialog-headers {
  position: relative;
  width: 100%;
  height: 64px;

  .monitor-dialog-title {
    position: absolute;
    top: 9px;
    left: 20px;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: #009DFF;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .monitor-dialog-close {
    position: absolute;
    right: -10px;
    top: 25px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}

.monitor-dialog-contents {
  .main {
    flex-direction: column;

    &-top {
      margin-top: 10px;

      >span {
        color: #80CEFF;
        font-size: 14px;
      }

      >textarea {
        resize: none;
        box-sizing: border-box;
        width: 480px;
        height: 190px;
        background: #001F32;
        border-radius: 5px 5px 5px 5px;
        color: #fff;
        outline: none;
        border: none;
        padding: 9px 12px;

        // overflow-y: hidden;
        &::placeholder {
          color: #B4C0CC;
          font-size: 14px;
        }
      }

    }

    &-footer {
      margin-top: 60px;

      >button {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        cursor: pointer;
      }

      :first-child {
        border: 1px solid #009DFF;
        background: rgba(0, 157, 255, 0.1);
        color: #B4C0CC;
        margin-right: 40px;
      }

      :last-child {
        border: 1px solid #009DFF;
        background: #009DFF;
        color: #fff;
      }
    }
  }
}
</style>