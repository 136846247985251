<template>
    <div v-if="overviewData">
        <el-dialog v-model="dialog_visible" modal-class="monitor-dialog-modal" custom-class="monitor-dialog"
            :close-on-click-modal="false" :close-on-press-escape="false" align-center :show-close="false"
            :style="'background-image: url(' + monitor_dialog_bg + ');background-size: cover'">
            <template #header>
                <div class="monitor-dialog-header">
                    <div class="monitor-dialog-title">分类统计</div>
                    <div class="monitor-dialog-close" @click="close"></div>
                </div>
            </template>
            <div class="monitor-dialog-content classification-statistics">
                <div class="classification-statistics-top flex">
                    <div class="classification-statistics-top-top flex flex-direction">
                        <div class="classification-title-row flex">
                            <div class="prefix"></div>
                            <div class="title-text">预警总数</div>
                        </div>
                        <div class="classification-content-row flex-1 flex justify-center align-center">
                            <div class="water-polo flex justify-center align-center">
                                <div class="water-polo-inner flex justify-center align-center">
                                    <div class="water-polo-inner-container">
                                        <div class="value-content">
                                            <div>{{ overviewData.size || 0 }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="classification-statistics-left-bottom flex flex-direction">
                        <div class="classification-title-row flex">
                            <div class="prefix"></div>
                            <div class="title-text">预警楼栋分布统计</div>
                        </div>
                        <div class="classification-content-row flex-1 flex justify-center align-center">
                            <div class="classification-statistics-right-content" style="height: 300px;">
                                <div v-for="(iter, index) in overviewData.buildingData" :key="index" class="event-item">
                                    <div class="flex justify-between align-center">
                                        <div class="fontSize14">{{ iter.buildIngName }}</div>
                                        <div style="color: #F5E74F;">
                                            <span>{{ iter.size }}</span>
                                            <span class="marginLeft5">({{ iter.size / overviewData.size * 100 || 0}}%)</span>
                                        </div>
                                    </div>
                                    <div class="marginTop10px">
                                        <el-progress :show-text="false" :stroke-width="16"
                                            :percentage="iter.size / overviewData.size * 100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="monitor-dialog-content-bottom">
                    <div class="classification-title-row flex">
                        <div class="prefix"></div>
                        <div class="title-text">近30天预警趋势</div>
                    </div>
                    <div class="monitor-dialog-content-bottom-main" ref="charts"></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import * as echarts from "echarts";
import { warningTrendEcharts } from "@/view/visualization/echarts/warningTrendEcharts"
import { queryIndexOnlyDataView } from "@/api/monitor"
export default {
    name: 'classificationStatistics',
    props: {
    },
    data() {
        return {
            monitor_dialog_bg: require('@/static/img/monitor/monitor-dialog-bg.png'),
            dialog_visible: true,
            overviewData: null,
        }
    },
    mounted() {
    },
    methods: {
        // 获取预警概况某一项数据
        async getOverviewData(id) {
            let res = await queryIndexOnlyDataView(id)
            if (res.code == 200) {
                this.overviewData = res.data
                let day = []
                let size = []
                res.data.warningEventTrend.reverse().forEach((item, index) => {
                    day.push(item.day)
                    size.push(item.size)
                })
                this.drawWarnings(day,size)
            }
        },
        /**
         * 绘制预警总数
         */
        drawWarnings(day,size) {
            this.$nextTick(() => {
                const charts = this.$refs["charts"]
                const myChart = echarts.init(charts)
                let title = "30"
                myChart.setOption(warningTrendEcharts(day,size,title))
            })
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>
  
<style lang="scss">
.monitor-dialog {
    --el-dialog-width: 1420px;
    height: 800px;
    --el-dialog-bg-color: transparent;
    --el-box-shadow: none;

    .el-dialog__body {
        padding: 0 !important;
    }
}
</style>
  
<style scoped lang="scss">
.event-item {
    /deep/ .el-progress-bar__outer {
        background: #191922;
    }

    /deep/ .el-progress-bar__inner {
        background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
    }
}

.monitor-dialog-header {
    position: relative;
    width: 100%;
    height: 64px;

    .monitor-dialog-title {
        position: absolute;
        top: 9px;
        left: 20px;
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #009DFF;
        line-height: 32px;
        letter-spacing: 1px;
    }

    .monitor-dialog-close {
        position: absolute;
        right: -10px;
        top: 25px;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}

.monitor-dialog-content {
    width: 1400px;
    height: 736px;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-bottom {
        height: 45%;

        &-main {
            height: calc(100% - 20px);
        }
    }
}

.classification-statistics {
    .classification-title-row {
        align-items: center;
        .prefix {
            margin-right: 8px;
            width: 4px;
            height: 20px;
            background: #009DFF;
            border-radius: 0 4px 0 0;
        }

        .title-text {
            font-size: 18px;
            color: #009DFF;
        }
    }

    .classification-content-row {
        .water-polo {
            width: 224px;
            height: 224px;
            border-radius: 50%;
            background: linear-gradient(90deg, rgba(89, 255, 255, 1), rgba(0, 157, 255, 1));

            .water-polo-inner {
                width: 220px;
                height: 220px;
                border-radius: 50%;
                background-color: #00000a;

                .water-polo-inner-container {
                    position: relative;
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    background: linear-gradient(90deg, #59FFFF 0%, #009DFF 100%);
                    overflow: hidden;

                    @keyframes rotate {
                        50% {
                            transform: translate(-50%, -73%) rotate(180deg);
                        }

                        100% {
                            transform: translate(-50%, -70%) rotate(360deg);
                        }
                    }

                    .value-content {
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        z-index: 30;

                        font-size: 32px;
                        color: #FFFFFF;
                        letter-spacing: 1px;
                        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

                        div {
                            font-family: YouSheBiaoTiHei;
                        }
                    }

                    // bai
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        width: 400px;
                        height: 400px;
                        top: 0;
                        left: 50%;
                        background-color: rgba(255, 255, 255, .4);
                        border-radius: 40%;
                        transform: translate(-50%, -70%) rotate(0);
                        animation: rotate 6s linear infinite;
                        z-index: 10;
                    }

                    // hei
                    &::after {
                        border-radius: 50%;
                        background-color: #00000a;
                        transform: translate(-50%, -70%) rotate(0);
                        animation: rotate 10s linear -5s infinite;
                        z-index: 20;
                    }
                }
            }
        }
    }

    &-top {
        width: 100%;
        height: 50%;
        overflow: hidden;

        &-top {
            width: 50%;
            // height: 50%;
        }

        &-bottom {
            width: 100%;
            height: 50%;
        }
    }

    &-right {
        width: 50%;

        &-content {
            padding-top: 32px;
            padding-left: 56px;
            padding-right: 36px;
            overflow-y: auto;
            height: 655px;

            &::-webkit-scrollbar {
                display: none
            }

            .event-item {
                margin: 0 auto 22px;
                width: 600px;
                height: 50px;
                flex-shrink: 0;
                color: white;
            }
        }
    }
}
</style>